import { debounce, get } from 'lodash';
import { createRef, forwardRef, useEffect, useState } from 'react';

import { ConditionalWrapper } from '@/components/conditional-wrapper';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { Tooltip } from '@/components/tooltip';
import { VisuallyHidden } from '@/components/visually-hidden';
import { Field, FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';

import { Error } from '../error';
import { Label } from '../label';

export const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  EMAIL: 'email',
} as const;

export type TextInputProps = Field &
  FieldErrors & {
    label: string;
    type: (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];
    variant?: 'light' | 'dark' | 'plain';
    alignment?: 'vertical' | 'horizontal';
    isTextArea?: boolean;
    isOptional?: boolean;
    size?: 'small' | 'medium' | 'large';
    hideErrorMessage?: boolean;
    showShadow?: boolean;
    hasSelectAll?: boolean;
    errorVariant?: 'solid';
  };

const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      label,
      type = INPUT_TYPES.TEXT,
      hideLabel = false,
      errors,
      hideErrorMessage,
      variant = 'light',
      alignment = 'vertical',
      name,
      placeholder,
      isTextArea = false,
      isOptional,
      instructions,
      tooltipContent,
      size = 'medium',
      showShadow = true,
      hasSelectAll = false,
      errorVariant,
      className,
      ...props
    }: TextInputProps,
    ref,
  ) => {
    const Component: React.ElementType = isTextArea ? 'textarea' : 'input';

    const fieldError = get(errors, name);
    const errorMessage = fieldError?.message;

    const inputRef = createRef<HTMLInputElement>();

    const [showPassword, setShowPassword] = useState(false);
    const [placement, setPlacement] = useState<'bottom' | 'right'>('bottom');

    const handleShowPassword = () => {
      const input = inputRef.current;
      setShowPassword(!showPassword);
      if (input) {
        input.type =
          input.type === INPUT_TYPES.PASSWORD
            ? INPUT_TYPES.TEXT
            : (INPUT_TYPES.PASSWORD as (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES]);
      }
    };

    const handleSelectAll = (event: React.MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    };

    // Set placement of tooltip based on window width
    useEffect(() => {
      function handleResize() {
        if (!tooltipContent) return;

        if (window.innerWidth < 960) {
          setPlacement('bottom');
        } else {
          setPlacement('right');
        }
      }

      // Run first time
      handleResize();

      const debounced = debounce(handleResize, 300);
      window.addEventListener(`resize`, debounced);

      return () => {
        window.removeEventListener(`resize`, debounced);
      };
    }, [tooltipContent]);

    const { base, input, error, labelStyle, showPasswordWrapper, showPasswordButton } = textInputStyles({
      variant,
      alignment,
      fieldError: !!fieldError,
      size,
      type,
      showShadow,
      hideLabel,
      isTextArea,
    });

    return (
      <div className={base({ class: className })} ref={ref}>
        {Boolean(label) && (
          <Label
            htmlFor={name}
            isHidden={hideLabel}
            isOptional={isOptional}
            alignment={alignment}
            className={labelStyle()}
          >
            {label}
          </Label>
        )}

        <ConditionalWrapper
          condition={!!tooltipContent}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={(children) => (
            <Tooltip
              placement={placement}
              content={tooltipContent}
              variant="dark"
              offset={placement === 'bottom' ? 25 : 10}
            >
              {children}
            </Tooltip>
          )}
        >
          <>
            <Component
              type={type}
              id={name}
              name={name}
              placeholder={placeholder}
              width="100%"
              height={size}
              className={input()}
              {...props}
              {...errors}
              ref={inputRef}
              onClick={hasSelectAll ? handleSelectAll : undefined}
            />
            {errorMessage && !hideErrorMessage && (
              <Error variant={errorVariant} className={error()}>
                {errorMessage}
              </Error>
            )}
            {instructions && (
              <Text variant="secondary" className="ml-1 mt-1.5 text-grey-500">
                {instructions}
              </Text>
            )}
          </>
        </ConditionalWrapper>
        {type === INPUT_TYPES.PASSWORD && (
          <Tooltip content={!showPassword ? 'Show password' : 'Hide password'}>
            <div className={showPasswordWrapper()}>
              <button
                onClick={handleShowPassword}
                id={`toggle-password-${name}`}
                role="switch"
                aria-checked={showPassword}
                type="button"
                className={showPasswordButton({ class: 'show-password-button' })}
              >
                <VisuallyHidden>Toggle password</VisuallyHidden>
                {!showPassword ? (
                  <Icon name="eye-off" size="20px" className="pointer-events-none" />
                ) : (
                  <Icon name="eye" size="20px" className="pointer-events-none" />
                )}
              </button>
            </div>
          </Tooltip>
        )}
      </div>
    );
  },
);

const textInputStyles = tv({
  slots: {
    base: 'relative w-full',
    input:
      'max-h-[var(--input-height)] w-full appearance-none rounded-md border border-grey-300 bg-white p-3 transition-all duration-200 ease-in-out placeholder:text-grey-400 focus:border-blue-500 focus:outline-none disabled:cursor-not-allowed disabled:border-grey-300 disabled:text-grey-400 md:text-baseSm hover:[&:not(:focus):not(:disabled)]:border-grey-400',
    labelStyle: 'block',
    error: 'mb-2 text-sm text-red-500',
    showPasswordWrapper: 'absolute right-3 items-center',
    showPasswordButton: 'rounded',
  },
  variants: {
    variant: {
      light: {
        input: 'text-text-primary',
      },
      dark: {
        input:
          'border-grey-800 bg-[#3F4143] text-white placeholder:text-grey-200 hover:border-grey-600 focus:ring-lightBlue-500 hover:[&:not(:focus):not(:disabled)]:border-lightBlue-500 [&_+_div_svg_path]:fill-grey-200',
      },
      plain: {
        input:
          'rounded-none border-none bg-transparent text-text-primary !shadow-none transition-none placeholder:text-grey-400 focus:!border-none focus:border-transparent focus:outline-none hover:[&:not(:focus):not(:disabled)]:border-transparent',
      },
    },
    alignment: {
      vertical: {},
      horizontal: {
        base: 'flex items-center whitespace-nowrap',
      },
    },
    fieldError: {
      true: {
        input: 'border-forms-states-error !shadow-[0_0_0_1px_theme(colors.forms.states.error)]',
        error: 'text-forms-states-error',
      },
    },
    size: {
      small: {
        input: 'max-h-8',
      },
      medium: null,
      large: {
        input: 'max-h-12 py-6',
      },
    },
    type: {
      password: {
        // Space to force the 1Password icon to not overlap the eye icon
        input: 'pr-[calc(theme(spacing.8)+theme(spacing.2))]',
      },
      text: null,
      number: null,
      email: null,
    },
    showShadow: {
      true: {
        input: 'shadow-softer',
      },
    },
    hideLabel: {
      true: {
        showPasswordWrapper: 'top-[11px]',
      },
      false: {
        showPasswordWrapper: 'top-8',
      },
    },
    isTextArea: {
      true: {
        input: 'max-h-none',
      },
    },
  },
  compoundSlots: [
    {
      slots: ['error'],
      fieldError: true,
      variant: 'dark',
      class: 'text-forms-states-errorLight',
    },
  ],
});

TextInput.displayName = 'TextInput';

export { TextInput };
